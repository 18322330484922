.content-info {
	background: $primary;
	color: #fff;
	padding: 80px 0 60px;

	h2 {
		margin: 0;
		padding: 0;
		font-weight: 700;
		font-size: 30px;
		letter-spacing: 0.4px;
	}

	a {
		color: #fff;
	}

	ul {

		li {
			line-height: 40px;

			.fa {
				width: 30px;
			}
		}

		.fabig {
			font-size: 30px;
		}
	}
}

.copynav {
	background: $notprimary;
	font-size: 12px;
	color: #fff;
	padding: 30px 0;
	line-height: 30px;

	.footer-menu {
		text-transform: uppercase;
		font-family: 'Exo', sans-serif;
		font-weight: 700;
		letter-spacing: 0.4px;

		ul {
			margin: 0;
		}

		li {
			padding: 0 4px;
			font-size: 15px;
			text-align: center;

			@media #{$tablet} {
				width: auto;
				padding-right: 30px;
			}
		}
	}

	a {
		color: #fff;
	}

	p {
		margin: 0;
	}

	@media #{$tablet} {
		padding: 15px 0;
	}

	@media #{$mobile} {
		padding: 15px 0;
	}
}

div.mailchimp-container {
	background: #F7F5F5;
	padding: 60px 0;

	div.email-field {
		width: calc(100% - 170px);
		float: left;
		margin-bottom: 10px;

		input[type="email"] {
			border: 1px solid #CCCCCC !important;
			padding: 10px 0 11px !important;
		}
	}

	div.submit-button {
		width: 140px;
		float: right;

		input[type="submit"] {
			padding: 0 !important;
			height: 45px !important;
		}
	}

	#mc_embed_signup .mc-field-group {
		margin: 0;
		width: 100%;
		padding: 0;
	}
}