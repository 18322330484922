.single-post-container {
	margin-bottom: 60px;

	.content-container {
		border-bottom: 1px solid #D9D9D9;
		margin-bottom: 30px;
		padding: 0 0 10px;

		img {
			border-radius: 9px;
			margin-bottom: 30px;
		}

		h1 {
			font-size: 30px;
			color: #555555;
			letter-spacing: 0.4px;
			margin: 0;
		}

		time {
			font-size: 12px;
			color: #606060;
			line-height: 21px;
		}
	}

	hr {
		height: 1px;
		background: #e9e9e9;
		border: 0;
		
	}
}

.single-sidebar {

	img {
		border-radius: 9px;
	}

	article {
		margin: 30px 0 0;
	}

	strong {
		line-height: 22px;
		display: inline-block;
		padding: 4px 0;
	}
}