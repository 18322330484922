* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	line-height: 21px;
	overflow-x: hidden;
	color: #000;
	font-size: 15px;
	font-family: 'Lato', sans-serif;
}

body.blog {
	background: #F7F5F5;
}

h1, h2, h3, h4, h5, h6 {
	line-height: 1.45em;
	font-family: 'Exo', sans-serif;
	font-weight: 700;
	letter-spacing: 0.5px;
	margin: 0;
}

h2.sub-title {
	border-radius: 0 9px 9px 0;
	float: left;
	font-size: 30px;
	padding: 15px 0 15px 0;
	color: #282464;
	line-height: 40px;
	position: relative;
	margin-top: 0px;
	margin-bottom: 0;

	@media #{$tablet} {
		margin-bottom: 30px;
	}
}

h2.alt-title {
	font-size: 30px;
	color: $primary;
	letter-spacing: 0.4px;
	margin: 10px 0 60px;
}

.page-header {
	border-bottom: 0px;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
	color: $red;

	&:hover {
		color: darken($red, 10%);
	}
}

.mobile-trigger {
	display: none;

	@media #{$mobile} {
		line-height: 30px;
		padding: 10px 0;
		font-size: 24px;
		color: #fff;
		background-color: $primary;
		display: block;
		text-align: center;

		&:focus,
		&:active,
		&:hover {
			color: #fff;
		}
	}
}

div.mobile {
	display: none;
	background-color: darken($primary, 2%);

	@media #{$mobile} {
		display: block;

		ul {
			list-style: none;
			padding: 0;

			a {
				padding: 15px;
				display: block;
				color: #fff;
			}
		}
	}	
}

img {
	max-width: 100%;
	height: auto;
}
.text-center {
	text-align: center;
}

.mt15  {    margin-top:  15px; }
.mt30  {    margin-top:  30px; }
.mt60  {    margin-top:  60px; }
.mt90  {    margin-top:  90px; }
.mt120 {    margin-top: 120px; }
.mt150 {    margin-top: 150px; }
.mb15  { margin-bottom:  15px; }
.mb30  { margin-bottom:  30px; }
.mb60  { margin-bottom:  60px; }
.mb90  { margin-bottom:  90px; }
.mb120 { margin-bottom: 120px; }
.mb150 { margin-bottom: 150px; }

main.main {
	padding: 0;

	@media #{$mobile} {
		padding: 0 15px 0 1;
	}
}

.mob30 {
	@media #{$mobile} {
		margin-top: 30px;
	}
}

.mejs-container {
	width: 100% !important;
	height: auto !important;
}
.mejs-mediaelement,
.carousel-image {
	position: relative !important;
	padding-bottom: 360px; /* 16:9 */
	height: 0 !important;

	@media #{$mobile} {
		padding-bottom: 54vw;
	}
}
.mejs-mediaelement video {
	max-width: 999% !important;
	position: absolute !important;
	top: 0 !important;
	bottom: 0 !important;
	right: 0 !important;
	left: 0 !important;
	width: 150% !important;
	height: 400% !important;
	margin: auto !important;
}