// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

.gform_wrapper {
	.gform_body {
		.gform_fields {

			#field_1_1,
			#field_1_2,
			#field_1_5,
			#field_1_6, {
				width: 50%;
				float: left;
				clear: none;
			}

			li.gfield {
				margin-top: 0 !important;
				margin-top: 0 !important;

				.ginput_container {
					margin: 0 !important;
				}

				label {
					font-size: 12px !important;
					color: #606060 !important;
					font-family: "Lato", sans-serif !important;
					line-height: 45px !important;
					margin: 0 !important; 
				}

				input[type="text"],
				textarea {
					border: 1px solid #D8D8D8 !important;
					line-height: 21px !important;
					padding: 12px !important;
					display: block !important;
					font-size: 15px !important;
				}
			}
		}
	}
}