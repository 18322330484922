.page-header {
	background-image: url(../../assets/images/title-bg.png); 
	background-size: cover;
	background-position: center center;
	height: 105px;
	padding: 15px 0;
	margin: 0;

	h1 {
		position: relative;
		color: #fff;
		background: rgba($primary,1);
		
		border-radius: 0 9px 9px 0;
		float: left;
		line-height: 75px;
		font-size: 42px;
		padding-right: 20px;

		@media #{$mobile} {
			padding-left: 30px;
		}

		&:before {
			position: absolute;
			height: 75px;
			width: 999px;
			left: -999px;
			top: 0;
			background: $primary;
			content: '';
		}
	}
}

.main-bg {
	padding: 60px 0 40px;
	color: rgba(255,255,255,0.87);
	background-size: cover;
	background-position: center center;
	margin-bottom: 60px;
	font-size: 18px;
	line-height: 24px;

	h2 {
		margin-bottom: 30px;
		display: block;
		text-align: center;
	}

	ul {

		li {
			font-size: 15px;
		}
	}
}

.goback {
	font-family: "Exo", sans-serif;
	font-weight: 700;
	font-size: 14px;
	text-transform: uppercase;
	color: #B6008D;
	letter-spacing: 0.5px;
}

.breadcrumb-container {
	margin-top: 15px;
	margin-bottom: 5px;
}

#breadcrumbs {
	text-transform: uppercase;
	font-family: 'Exo', sans-serif;
	font-weight: 700;
	letter-spacing: 0.5px;	
}

#breadcrumbs a {
	text-transform: uppercase;
	font-family: 'Exo', sans-serif;
	font-weight: 700;
	color: $red;
	letter-spacing: 0.5px;
}

dl {

	dt, dd {
		float: left;
		margin-top: 10px;
		margin-bottom: 20px;
		font-size: 17px;
	}

	dt {
		width: 40px;

		.fa {
			color: $primary;
			font-size: 18px;
			width: 40px;
		}
	}

	dd {
		width: calc(100% - 40px);
	}
}

.carousel-image {
	background-size: cover;
	background-position: center;
}

.carousel-video {
	position: relative;

	.overlay {
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: 10;
	}
}

.carousel-control {
	background: transparent !important;
}

.solution-container {
	display: block;
	position: relative;
	margin-bottom: 30px;
	height: 210px;
	box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24);
	border-radius: 9px;

	.top {
		height: 210px;
		background-size: cover;
		background-position: center center;		
		border-radius: 9px;
	}

	.logo {
		background: rgba(255,255,255,0.9);
		height: 100px;
		min-width: 50%;
		position: absolute;
		border-radius: 9px 0 9px 0;
	}

	.bottom {
		position: absolute;
		bottom: 0;
		width: 100%;
		background: #fff;
		color: #000;
		line-height: 20px;
		padding: 10px 15px;
		border-radius: 0 0 9px 9px;
		font-size: 15px;
	}

	.centering {
		height: 100px;
		width: 100%;
		display: table;

		.center {
			display: table-cell;
			vertical-align: middle;
			text-align: center;

			img {
				max-width: 80%;
				height: auto;
			}
		}
	}

	&:hover {
		box-shadow: 0 0 24px 0 rgba(0,0,0,0.12), 0 2px 24px 0 rgba(0,0,0,0.24);
		-webkit-transition: 0.2s ease-in-out;
	    -moz-transition: 0.2s ease-in-out;
	    -o-transition: 0.2s ease-in-out;
	    transition: 0.2s ease-in-out;
	}
}

.map-container {
	position: relative;
	padding-bottom: 100%; /* 16:9 */
	height: 0;
}
.map-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.post-container {
	width: calc(50% - 30px);
	float: left;
	margin: 0 15px 30px;
	background: #FFFFFF;
	box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24);
	border-radius: 9px;
	padding: 15px;

	h1 {
		font-size: 20px;
		line-height: 25px;
		height: 25px;
		overflow: hidden;
		font-size: 21px;
		color: #555555;
		letter-spacing: 0.28px;
		line-height: 28px;

		a {
			color: #555;
		}
	}

	.post-content {
		height: 60px;
		line-height: 20px;
		font-size: 15px;
		margin: 15px 0 22px;
		overflow: hidden;
	}

	time {
		font-size: 12px;
		color: #606060;
		line-height: 21px;
	}

	.pull-right {

		a {
			text-transform: uppercase;
		}
	}

	@media #{$mobile} {
		width: calc(100% - 30px);
	}
}

ul,ol {
	padding-left: 17px;
}

.product-row {
	padding-bottom: 90px;

	.product-content {

		h2 {
			font-weight: 700;
			font-size: 30px;
			color: #282464;
			letter-spacing: 0.4px;
			line-height: 38px;
			margin: 60px 0 30px;
		}
	}

	.product-image {
		margin-top: 130px;
		margin-bottom: 30px;

		text-align: left;
	}

	.product-video {
		position: relative;
		padding-bottom: 56%; /* 16:9 */
		height: 0;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	&.product-right {
		background-color: #F7F5F5;

		.product-image {

			text-align: right;
		}
	}
}

.about-row {

	.about-content {

		h2 {
			font-weight: 700;
			font-size: 30px;
			color: #282464;
			letter-spacing: 0.4px;
			line-height: 38px;
			margin: 0px 0 30px;
		}
	}

	.about-image {
		margin-top: 60px;

		@media #{$mobile} {
			margin-top: 20px;
		}
	}

	.about-content {
		height: 260px;

		.centering {
			display: table;
			height: 260px;

			.center-this {
				display: table-cell;
				vertical-align: middle;
				
				.bg {
					margin: 60px 0;
					background: rgba(255,255,255,0.80);
					border-radius: 9px;
					padding: 30px;
				}
			}
		}

		@media #{$mobile} {
			height: auto;
			margin-top: 20px;

			.centering {
				display: table;
				height: auto;

				.center-this {
					display: table-cell;
					vertical-align: middle;
				}
			}
		}
	}

	&.about-right {
		background-color: #F7F5F5;
	}

	&:last-child {
		padding-bottom: 90px;

		@media #{$mobile} {
			padding-bottom: 30px;
		}
	}
}

.contact-modal {
	background: #fff;
	padding: 15px 15px 15px 30px;
	border-radius: 4px;
}

.bigbar {
	background: #fff;
	width: 100%;
	border: 0;
	box-shadow: none;
	color: #fff;
	margin: -1px 0 0;
	font-size: 15px;
	color: #FFFFFF;
	letter-spacing: 0.2px;
	text-align: center;
	font-weight: 700;
	text-transform: uppercase;
	line-height: 45px;
	position: sticky;
	top: 0;
	z-index: 2;
	padding: 10px 0;
	box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24);
}

.modal-dialog {
	width: 100%;
	max-width: 1140px;
	padding: 0 15px !important;
	margin: 5vw auto 0;
}

.modal-content {
	margin-top: 60px;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	background: transparent;

	button {
		background: transparent;
		border: 0;
		color: #fff;
		font-size: 30px;
		padding: 0;
	}

	.modal-body {
		padding: 0;
		padding-top: 5px;
	}
}

.iframe-container {
	position: relative;
	padding-bottom: 56%; /* 16:9 */
	height: 0;
	margin-bottom: 60px;
}
.iframe-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.bio-header {

	h2 {
		font-size: 30px;
		color: #282464;
		letter-spacing: 0.4px;
		text-align: center;
		line-height: 38px;
		margin-bottom: 30px;
	}

	p {
		margin-bottom: 30px;
	}
}

.bio-list {
	text-align: center;
	margin-bottom: 90px;

	li {
		width: calc((100% / 7));
		margin-left: -4px;
		position: relative;

		header {
			height: 100px;
			background-size: cover;
			background-position: relative;
			margin-bottom: 1px;
		}

		main {
			background: $primary;
			height: 100px;
			text-align: center;
			padding: 0 5px;

			table {
				width: 100%;
				text-align: center;
				height: 100px;

				tr {

					td {
						vertical-align: middle;

						h2 {
							color: #fff;/* Loading: */
							font-size: 18px;
							letter-spacing: 0.24px;
							text-align: center;
							line-height: 1.5em;
							display: block;
						}
					}
				}
			}
		}

		.arrow {
			position: relative;
			height: 30px;

			&:after {
				position: absolute;
				content: "\f078";
				top: 50%;
				bottom: -20px;
				font-size: 20px;
				color: #d61016;
				font-family: FontAwesome;
				margin-left: -10px;
			}
		}
	}
}

.image-sep {
	height: 450px;
	background-size: cover;
	background-position: center center;

	@media #{$tablet} {
		height: auto;
		padding-top: 45%;
	}
}

.bio-row {
	margin-top: -450px;
	position: relative;
	margin-bottom: 10px;

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 450px;
		background: rgba(#282464, 0.0);
	}

	&:hover {
		.overlay {
			background: rgba(#282464, 0.3);
		}
	}

	.bio-content {

		h2 {
			font-weight: 700;
			font-size: 30px;
			color: #282464;
			letter-spacing: 0.4px;
			line-height: 38px;
			margin: 0px 0 30px;
		}
	}

	.bio-image {
		margin-top: 60px;

		@media #{$mobile} {
			margin-top: 20px;
		}
	}

	.bio-content {
		height: 450px;
		padding: 80px 0;

		.centering {
			display: table;
			height: auto;
			vertical-align: middle;

			.center-this {
				display: table-cell;
				vertical-align: middle;
				
				.bg {
					background: rgba(255,255,255,0.80);
					border-radius: 9px;
					padding: 30px;

					.clearfix {
						margin-top: 30px;
					}
				}
			}
		}
	}

	@media #{$tablet} {
		position: relative;
		margin-top: 0;

		.overlay {
			display: none;
		}

		.bio-content {

			h2 {
				font-weight: 700;
				font-size: 30px;
				color: #282464;
				letter-spacing: 0.4px;
				line-height: 38px;
				margin: 0px 0 30px;
			}
		}

		.bio-image {
			margin-top: 60px;
		}

		.bio-content {
			padding-top: 45px;
			padding-bottom: 60px;
			height: auto;

			.centering {
				display: table;
				height: auto;
				width: 100%;
				vertical-align: middle;

				.center-this {
					display: table-cell;
					vertical-align: middle;
					
					.bg {
						background: rgba(255,255,255,1);
						border-radius: 0px;
						padding: 15px;
						margin: -15px;
						width: calc(100% + 30px);

						.clearfix {
							margin-top: 30px;
						}
					}
				}
			}
		}
	}
}

.row.translationrow {padding:15px 0!important;}

.thumbnail-bg {
	height: 200px;
	margin: -15px 0 20px -15px;
	width: calc(100% + 30px);
	background-size: cover;
	background-position: center center;
	border-radius: 9px 9px 0 0;
}

.spanish-flag,
.english-flag,
.german-flag {
	display: inline-block;
	margin-right: 10px;
}