.btn {

	&.btn-primary {
		background: $red;
		color: #fff;
		padding: 0 30px;
		line-height: 45px;
		font-size: 14px;
		font-family: "Exo", sans-serif;
		font-weight: 700;
		letter-spacing: 0.5px;
		border: 0;
		border-radius: 500px;
		text-transform: uppercase;

		&:hover {
			background: darken($red, 10%);
		}
	}
}

.gform_wrapper input[type="submit"] {
	background: $red !important;
	color: #fff !important;
	padding: 0 30px !important;
	line-height: 45px !important;
	font-size: 14px !important;
	font-family: "Exo", sans-serif !important;
	font-weight: 700 !important;
	letter-spacing: 0.5px !important;
	border: 0 !important;
	border-radius: 500px !important;
	text-transform: uppercase !important;

	&:hover {
		background: darken($red, 10%);
	}
}



a.btn.button.brochure {

	background: $red !important;
	color: #fff !important;
	padding: 0 30px !important;
	line-height: 45px !important;
	font-size: 14px !important;
	font-family: "Exo", sans-serif !important;
	font-weight: 700 !important;
	letter-spacing: 0.5px !important;
	border: 0 !important;
	border-radius: 500px !important;
	text-transform: uppercase !important;
	margin:15px 0;

	&:hover {
		background: $notprimary;
	}


}