// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Responsive
$mobile: 				"all and (max-width:  768px)";
$tablet: 				"all and (max-width:  991px)";
$desktop: 				"all and (max-width: 1199px)";

// Colors
$primary:         		rgba(182, 12, 47,1);
$notprimary:			rgba(40, 36, 100,1);
$red:					rgba(182, 12, 47,1);
