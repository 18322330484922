.contact-information {
	line-height: 21px;
	padding: 12px 0;
	border-bottom: 1px solid #a7a7a7;
	color: #555555;
	font-family: 'Exo', sans-serif;
	font-size: 14px;

	ul {
		margin: 0;
		padding: 0;

		li {
			margin-left: 15px;

			.fa {
				width: 20px;
				text-align: center;
			}
		}

		@media #{$mobile} {
			text-align: center;

			li {
				margin: 7.5px;
			}
		}
	}

	a {
		color: #555;
	}
}

.languages {text-align:center;}
.german-flag,.spanish-flag,.english-flag {margin:5px 0;}

@media #{$mobile} {
		.german-flag,.spanish-flag,.english-flag {margin:5px 0;}
	}



.banner {
	padding: 25px 0;

	font-family: 'Exo', sans-serif;

	.brand {
		display: block;

		img {
			max-width: 300px;
		}
	}

	a {
		color: #555;
	}

	.nav-primary {
		position: relative;
		display: block;
		margin-top: 15px;

		&:before {
			position: absolute;
			content: '';
			width: 8px;
			height: 8px;
			background: #fff;
			border: 1px solid $red;
			bottom: 9px;
			z-index: 5;
			left: -38px;
			border-radius: 15px;
		}

		&:after {
			position: absolute;
			content: '';
			width: 40px;
			height: 1px;
			background: $red;
			bottom: 7px;
			z-index: 4 !important;
			left: -40px;
			border-radius: 15px;
		    -ms-transform: rotate(45deg); /* IE 9 */
		    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
		    transform: rotate(45deg);
		    bottom: 0;
		    left: -42px;
		}

		ul {
			position: relative;
			display: block;

			&:before {
				position: absolute;
				content: '';
				width: 8px;
				height: 8px;
				background: #fff;
				border: 1px solid $red;
				bottom: -18px;
				z-index: 5;
				left: -6px;
				border-radius: 15px;
			}

			li {
				margin: 0;
				padding: 0;

				a {
					padding: 15px 16px;
					font-weight: 700;
					text-transform: uppercase;
					border-bottom: 1px solid rgba($red, 0.25);
					position: relative;

					&:before, &:after {
						position: absolute;
						content: '';
						width: 8px;
						height: 8px;
						background: #fff;
						border: 1px solid rgba($red, 0.25);
						bottom: -4px;
						border-radius: 15px;
						z-index: 4;
						left:-6px;
					}

					&:before {
						left: -6px;
					}

					&:after {
						right: -6px;
					}
				}

				a:hover {
					color: $red;
					border-bottom: 1px solid $red;
					text-decoration: none;

					&:before, &:after {
						position: absolute;
						content: '';
						width: 8px;
						height: 8px;
						background: #fff;
						border: 1px solid $red;
						bottom: -4px;
						z-index: 5;
					}

					&:before {
						left: -6px;
					}

					&:after {
						right: -6px;
					}
				}
			}


			.current_page_item {
				
				a {
					color: $red;
					border-bottom: 1px solid $red;
					position: relative;

					&:before, &:after {
						position: absolute;
						content: '';
						width: 8px;
						height: 8px;
						background: #fff;
						border: 1px solid $red;
						bottom: -4px;
						z-index: 5;
						border-radius: 15px;
					}

					&:before {
						left: -6px;
					}

					&:after {
						right: -6px;
					}
				}
			}
		}
	}
}